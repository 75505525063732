import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Navigation from "../components/Navigation"
import { PageContainer } from "../components/PageContainer"
import { Container } from "../components/Container"
import Footer from "../elements/Footer"

const NotFoundPage = () => {
  useEffect(() => {
    // Redirect to the homepage ("/") after a delay
    const redirectTimeout = setTimeout(() => {
      navigate('/');
    }, 500); // 3 seconds delay before redirecting

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <>
      <PageContainer>
        <Navigation />
        <Container className="mt-16 mb-16">
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4">404 - Page Not Found</h1>
          <p>You will be redirected to the homepage shortly...</p>
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>
